<template>
  <div>
    <b-alert
      v-if="isPreview"
      class="mb-3"
      show
      variant="primary"
    >
      <div class="alert-body">Vous visualisez actuellement agriprogress en tant que {{ iaaData.name }}
        <b-badge
          :to="{name: 'admin-iaa-list'}"
          :pill="true"
          class="ml-1"
          variant="primary"
        >
          Quitter
        </b-badge></div></b-alert>
    <router-view
      v-if="iaaData"
      :key="$route.fullPath"
    />
  </div>
</template>

<script>
import { BAlert, BBadge } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import { mapState } from "vuex";

export default {
  components: {
    BAlert,
    BBadge
  },
  data() {
    return {
      userData: getUserData()
    };
  },
  computed: {
    ...mapState("verticalMenu", ["currentPeriod"]),
    ...mapState("iaa", ["iaaData", "currentFilters", "filtersOptions"]),
    isPreview() {
      return this.userData.type === "Admin" && this.iaaData !== null;
    }
  },
  watch: {
    currentPeriod() {
      this.updateData();
    }
  },
  created() {
    this.$store.commit("verticalMenu/SET_IAA_NAVIGATION");

    if (!this.coopHveAudit || this.iaaData.id !== this.$route.params.coop_id) {
      this.getIaaHveAudit();
    }
  },
  methods: {
    getIaaHveAudit() {
      this.$http.GetIndustry({ id: this.$route.params.iaa_id, period: this.currentPeriod })
        .then(({ data }) => {
          this.$store.commit("iaa/SET_IAADATA", data);
          this.$store.commit("iaa/SET_IAA_COOPERATIVE_FILTER");
          this.$watch("currentFilters", () => this.updateData(), { deep: true });
        })
        .catch(console.warn);
    },
    updateData() {
      this.$http.GetIndustry({
        id: this.$route.params.iaa_id,
        period: this.currentPeriod,
        ...Object.keys(this.currentFilters).reduce((acc, filter) => {
          if (this.currentFilters[filter].length < this.filtersOptions.find(opt => opt.id === filter).options.length) {
            acc[filter] = this.currentFilters[filter];
          }
          return acc;
        }, {})
      })
        .then(({ data }) => this.$store.commit("iaa/SET_IAADATA", data))
        .catch(console.warn);
    }
  }
};
</script>
